<template>
  <div id="app" class="app" ref="app">
    <Layout />
    <!-- <Web3Page /> -->
  </div>
</template>

<script>
import Layout from "@/layout";
// import Web3Page from "@/views/web3/index.vue";
export default {
  name: "App",
  components: {
    Layout,
   
  },
  watch:{
    // "$store.state.mask.showGolden"(val, oldval) {
    //   if (val == true) {
    //     this.$refs.app.className = "dark";
    //   } else {
    //     this.$refs.app.className = "light";
    //   }
    // },
    // deep: true,
    // immediate: true,
  }
};
</script>

<style>
/* ::-webkit-scrollbar {
  display: none;
  scrollbar-width: none !important;
} */

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

.dark{
  background: #000;
}
.light{
  background: #fff;
}
</style>
