import Vue from "vue";
import store from "@/store/index.js";
import router from "@/router/index.js";
export function initSocket() {
  if (typeof WebSocket === "undefined") {
    alert("您的浏览器不支持socket");
  } else {
    // 实例化socket
    let socketUrl = process.env.VUE_APP_WSS + "/connect";
    let socket = new WebSocket(socketUrl);
    socket.init = async function () {
      let that = this;
      // const accounts = await window.ethereum.request({
      //   method: "eth_requestAccounts",
      // });
      // const account = accounts[0];
      const res = {};
      let params1 = {
        event: "goods_detail:11",
        // message: "test_message",
      };
      const {
        path,
        query
      } = router.currentRoute;
      // console.table("socket.readyState", that.$socket.readyState);
      res["params1"] = params1;

      // if (path == "/raffle-detail" && query.id) {
      //   let params2 = {
      //     event: "power_raffle_game_detail:" + query.id,
      //     // message: "test_message",
      //   };
      //   console.log(router.currentRoute, `.query.id`);
      //   res["params2"] = params2;
      // }
      store.commit("socket/EVENT_PARMAS", JSON.stringify(res));
      // console.log(JSON.stringify(res), "clientMessage111");
      return Promise.resolve(JSON.stringify(res));
      // console.log("socket.readyState", socket.readyState);
      // setTimeout(() => {
      //   if (that.$socket.readyState == 1) {

      //   }
      // }, 1000);
    };

    var heartCheck = {
      timeout: 10000,
      timeoutObj: null,
      serverTimeoutObj: null,
      reset: function () {
        clearTimeout(this.timeoutObj);
        clearTimeout(this.serverTimeoutObj);
        return this;
      },
      start: function () {
        var that = this;
        this.timeoutObj = setTimeout(function () {
          let message = {
            event: "ping",
          };
          socket.send(JSON.stringify(message));
          that.serverTimeoutObj = setTimeout(function () {
            // console.log("message", "关闭链接");
            socket.close(); //如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
          }, that.timeout);
        }, that.timeout);
      },
    };
    // console.log("Socket start", socket);
    socket.heartCheck = heartCheck;

    // 监听socket连接
    socket.onopen = function open(msg) {
      // console.log("socket连接成功 open", msg);
      store.commit("socket/SOCKET_ONOPEN", msg);
      // socket.heartCheck.reset().start();
    };
    // 监听socket错误信息
    socket.onerror = error;
    // 监听socket消息
    socket.onmessage = getMessage;
    socket.onclose = close;

    function error() {
      console.log("连接错误");
    }

    function getMessage(msg) {
      //如果获取到消息，心跳检测重置
      // socket.heartCheck.reset().start();
      //更新 数据
      // console.log(`更新数据`, msg);
      if (msg.data == "pong") {
        // console.log(msg, "pong");
        heartCheck.reset().start();
        socket
          .init()
          .then((res) => {
            const data = JSON.parse(res)
            // console.log("clientMessage res", typeof JSON.parse(res), data);
            Object.values(data).map(item => {
              socket.send(JSON.stringify(item));
            });
          })
          .catch((err) => {
            // console.log(err, "初始化失败");
          });
      } else {
        let {
          event,
          message
        } = JSON.parse(msg.data);
        if (event.indexOf("goods_detail:11") != -1) {
          const orderInfo = {
            stock: message
          }
          store.commit("order/POST_GOODS_INFO", orderInfo);
        } else if (message.indexOf("ping") != -1) {
          heartCheck.reset().start();
          // console.log("ws链接正常");
        }
      }
    }

    function close(msg) {
      // console.log("socket已经关闭", msg);
      store.commit("socket/SOCKET_ONCLOSE", msg);
    }
    return socket;
  }
}

export function clearEvent(account) {
  let that = this;
  let params = {
    event: "cancel",
    message: "goods_detail:11",
  };
  let clientMessage = JSON.stringify(params);
  Vue.prototype.$socket.send(clientMessage);
  // console.log(Vue.prototype.$socket, 'Vue.prototype.$socket')
}