<template>
  <div class="navigation">
    <!-- menu -->
    <div class="menu_m">
      <div class="logo">
        <img src="/assets/home/logo.png" alt="" />
      </div>
      <div @click="Menu" v-show="!menu_status" class="menu_open">
        <img src="/assets/home/menu.png" alt="" />
      </div>
      <div v-show="menu_status" class="menu_close">
        <div class="menu_dal">
          <div class="menuimg">
            <img class="logoed" src="/assets/home/logo.png" alt="" />
            <img
              class="chaed"
              @click="Menu"
              src="/assets/home/cha.png"
              alt=""
            />
          </div>
          <div class="menu_box">
            <div class="menu_box_li" @click="home">
              <p class="menu_text">Home</p>
            </div>
            <div class="menu_box_li" @click="about">
              <p class="menu_text">About</p>
            </div>
            <div class="menu_box_li" @click="stake">
              <p class="menu_text">Stake</p>
            </div>
            <div class="menu_box_li" @click="opensea">
              <p class="menu_text">Opensea</p>
              <div class="menu_img">
                <img src="/assets/home/opensea.png" alt="" srcset="" />
              </div>
            </div>
            <div class="menu_box_li" @click="twitter">
              <p class="menu_text">Twitter</p>
              <div class="menu_img">
                <img src="/assets/home/twitter.png" alt="" srcset="" />
              </div>
            </div>
            <div class="menu_box_li" @click="game">
              <p class="menu_text">Discord</p>
              <div class="menu_img">
                <img src="/assets/home/game.png" alt="" srcset="" />
              </div>
            </div>
            <!-- <div class="menu_box_li">
                  <p class="menu_text">OPENSEA</p>
                  <div class="menu_img">
                    <img src="/assets/home/share.png" alt="" srcset="" />
                  </div>
                </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navigation",
  data() {
    return {
      menu_status: false,
    };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    opensea() {
      window.open("https://opensea.io/collection/producerc");
    },
    twitter() {
      window.open("https://twitter.com/producerc_nft");
      this.menu_status = !this.menu_status;
    },
    game() {
      window.open("https://discord.gg/producerc");
      this.menu_status = !this.menu_status;
    },
    Menu() {
      this.menu_status = !this.menu_status;
    },
    home() {
      this.$router.push("/");
      this.menu_status = !this.menu_status;
    },
    about() {
      this.$router.push("/about");
      this.menu_status = !this.menu_status;
    },
    stake() {
      this.$router.push("/stake");
      this.menu_status = !this.menu_status;
    },
  },
};
</script>
<style lang="scss" scoped>
// 移动端样式
@media (max-width: 1024px) {
  .menu_m {
    width: 100%;
    height: 410px;
    background: url(https://img.ohdat.io/producer_c/official_website/assets/home/meau.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    .logo {
      width: 205px;
      height: 205px;
      margin-left: 102px;

      img {
        width: 100%;
      }
    }

    .menu_open {
      width: 154px;
      height: 154px;
      margin-right: 102px;

      img {
        width: 100%;
      }
    }

    .menu_close {
      width: 205px;
      height: 205px;
      margin-right: 102px;
      position: relative;

      .menu_dal {
        width: 100%;
        // height: 973px;
        height: 100vh;
        // background: url('/assets/home/beijing.png') no-repeat;
        // background-size: 100% 100%;
        background: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(50px);
        -webkit-backdrop-filter: blur(50px);
        position: fixed;
        top: 0;
        right: 0;

        .menuimg {
          width: 90%;
          margin: auto;
          height: 410px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .logoed {
            width: 205px;
            height: 205px;
            // margin-left: 102px;
          }

          .chaed {
            width: 154px;
            height: 154px;
            // margin-right: 102px;
          }
        }

        .menu_box {
          width: 100%;
          // height: 425px;
          overflow: hidden;

          .menu_box_li {
            width: 90%;
            margin: auto;
            height: 259px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 26px;
            border-bottom: 3px solid #ffffff;

            .menu_text {
              font-size: 123px;
              font-family: "B-Bold";
              font-weight: 400;
              color: #ffffff;
              // margin-left: 51px;
            }

            .menu_img {
              width: 123px;
              height: 123px;
              // margin-right: 26px;

              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>